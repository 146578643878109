import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import CartContext from '../../context/CartContext';

const ProductQuantityWrapper = styled.div`
    margin-bottom: 20px;
    > strong {
        display: block;
    }
    > form {
        display: flex;
        flex-direction: row;
        width: 50%;
    }
    > form > button {
        white-space: nowrap
    }
`;

const Input = styled.input`
    border: 1px solid #ccc;
    display: block;
    padding: 5px 10px;
    width: 60px;
    margin-right: 10px;
    &:focus {
        border-color: black
    }

`;

export default function ProductQuantityAdder({ variantId, available }) {
    const [quantity, setQuantity] = React.useState(1);
    
    const {updateLineItem} = React.useContext(CartContext);

    const handleQuantityChange = e => {
        setQuantity(e.currentTarget.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updateLineItem({ variantId, quantity: parseInt(quantity, 10) });        
    }
    console.log('variantId: ' + variantId)

    return (
        <ProductQuantityWrapper>
            <strong>Quantity</strong>
                <form onSubmit={handleSubmit}>
                    <Input
                        disabled={!available}
                        type="number"
                        min="1"
                        // Restrict Coins to one per customer
                        max={ variantId === "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzg2Mzg2OTkwNzExNA==" ? 1 : null }
                        step="1"
                        value={quantity}
                        onChange={handleQuantityChange} />
                    <Button disabled={!available} type="submit" className="ussIdahoButton">Add to cart</Button>
                </form>
            
        </ProductQuantityWrapper>
    )
}
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const ImageThumbnailWrapper = styled.div`
    cursor: pointer;
    border: 1px solid ${props => props.isActive ? '#638FCB' : '#eeeeee' }; 
    border-radius: 5px;
    width: 100%;
`

const ImageThumbnail = ({ isActive, onClick, image  }) => {

    const handleClick = () => {
        onClick(image)
    }

    return (
        <>
        <ImageThumbnailWrapper onClick={handleClick} isActive={isActive}>
            {image.localFile && (
                <GatsbyImage image={getImage(image.localFile)} className="d-block m-auto" style={{height: '100%'}}/>
                // <Img fluid={image.localFile?.childImageSharp.fluid} style={{height: '100%'}} className="d-block m-auto"/>
            )}
        </ImageThumbnailWrapper>
        </>
    )
}

export default ImageThumbnail
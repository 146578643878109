import React from "react"
import { graphql, Link } from "gatsby"
import CartContext from "../../context/CartContext"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShoppingCart } from "@fortawesome/pro-solid-svg-icons"

import styled from "styled-components"

import { StyledLink } from "../StyledLink/StyledLink"

const CartWrapper = styled(StyledLink).attrs(() => ({
  to: "/cart",
}))`
  margin-left: auto;
`

const Cart = () => {
  const { checkout } = React.useContext(CartContext)
  
  let totalQuantity = 0
  if (checkout) {
    checkout.lineItems.forEach(lineItem => {
      totalQuantity = totalQuantity + lineItem.quantity
    })
  }

  
  const total = checkout?.totalPrice?.amount 
  ? parseFloat(checkout.totalPrice.amount).toFixed(2) 
  : "0.00"

  return (
    <>
      <CartWrapper>
        <Link to="/cart">
          {" "}
          <FontAwesomeIcon
            icon={faShoppingCart}
            style={{
              fontSize: "1.5rem",
              marginRight: "5px",
              color: "#003366",
            }}
            aria-hidden="true"
          />
           <span style={{ fontSize: "1.25rem" }}>
            {totalQuantity || 0} item(s) / ${total}
          </span>
        </Link>
      </CartWrapper>
    </>
  )
}

export default Cart

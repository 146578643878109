/* eslint-disable no-unused-vars */
import React from 'react'
import { graphql, Link } from 'gatsby'
import CartContext from '../context/CartContext'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageTitle from '../components/PageTitle/PageTitle'
import {navigate, useLocation} from '@reach/router'
import queryString from 'query-string'

import { Button } from 'react-bootstrap'

import ProductImageGallery from '../components/ProductImageGallery/ProductImageGallery'
import ProductQuantityAdder from '../components/ProductQuantityAdder/ProductQuantityAdder'

import Img from 'gatsby-image'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import Cart from '../components/Cart/Cart'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft
    } from "@fortawesome/pro-solid-svg-icons"

import styled from 'styled-components'
import { StickyContainer, Sticky } from 'react-sticky';

const Grid = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;


    @media(min-width: 768px) {
        grid-template-columns: 30% auto;
        > div:first-child {
            order: 2;
        }
        > div:last-child {
            order: 1;
        }
    }
`;

const SelectWrapper = styled.div`
  margin-top: 40px;
  > strong {
    display: block;
    margin-bottom: 8px;
  }
`;

const Price = styled.div`
  margin: 40px 0;
  font-weight: bold;
  font-size: 30px;
`;




// passing in pagecontext from node query with $shopifyId
export const query = graphql`
  query ProductQuery($shopifyId: String) {
    shopifyProduct (shopifyId: {eq: $shopifyId }) {
      shopifyId
      title
      descriptionHtml
      handle
      id
      images {
        localFile {
          childImageSharp {
            fluid(cropFocus: CENTER, fit: COVER) {
              srcSet
            }
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default function ProductTemplate(props) {
  //console.log(props)
  const { getProductById } = React.useContext(CartContext);
  const [product, setProduct] = React.useState(null);
  const [selectedVariant, setSelectedVariant] = React.useState(null);
  const {search, origin, pathname} = useLocation();
  const variantId = queryString.parse(search).variant;
  console.log(typeof parseFloat(selectedVariant?.price.amount))

  React.useEffect (() => {
    getProductById(props.data.shopifyProduct.shopifyId).then(result => {
      //console.log(result)
      setProduct(result);
      setSelectedVariant(result.variants.find(({ id }) => id === variantId) || result.variants[0]);
   });
   },[
      getProductById, 
      props.data.shopifyProduct.shopifyId, 
      setProduct,
      variantId
    ]);
  
  const productDescription = props.data.shopifyProduct.descriptionHtml

  const handleVariantChange = (e) => {
    const newVariant = product?.variants.find(v => v.id === e.target.value);
    setSelectedVariant(newVariant);
    navigate(`${origin}${pathname}?variant=${encodeURIComponent(newVariant.id)}`, {
      replace: true
    })
  };
    
  return (
    <Layout>
      <SEO title={props.data.shopifyProduct.title} />
      <PageTitle pageTitle={props.data.shopifyProduct.title} link="/" />
      
      <ContentBlock>
        {/* <StickyContainer>
      <Sticky>
        {({ style, isSticky }) => <div style={{ ...style, paddingTop: isSticky ? '130px' : '0px', boxShadow: isSticky ? ' 0px 3px 5px 0px rgba(0,0,0,0.5)' : 'none', zIndex: '100', backgroundColor: 'white' }}><h2 style={{textAlign: 'center'}}>Use code <span style={{fontSize: 'larger', color: 'green'}}>SSN799</span> at checkout to receive 20% off your order!</h2></div>}
        </Sticky> */}
        <Button className="ussIdahoButton" onClick={() => navigate(-1)}>
          <FontAwesomeIcon
                    icon={faAngleLeft}
                    style={{
                        
                        marginRight: '5px',
                    }}
                    aria-hidden="true"
                    
                />Back to products</Button>
        <Grid>
        <div style={{
          marginTop: '1rem'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
            <div>
              <h2>{props.data.shopifyProduct.title}</h2>
            </div>
            <div>
              <Cart />
            </div>
          </div>
                  {/* optional chaining replaces: {product && product.availableForSale ? () : null } */}
                  { product?.availableForSale && !!selectedVariant && (
            <>
              {product?.variants.length > 1 ? (
                <SelectWrapper>
                <strong>Variant{' '}</strong>
                <select defaultValue={null} 
                //value={selectedVariant.id}
                  onBlur={handleVariantChange}>
                  {product?.variants.map(v => (
                    <option key={v.id} value={v.id}>
                      {v.title}
                    </option>
                  ))}
                </select>
                </SelectWrapper>
              ) : null } 
              {!!selectedVariant && 
              <>
              <Price>
                ${parseFloat(selectedVariant.price.amount).toFixed(2)}
                </Price>
                <ProductQuantityAdder 
                  variantId={selectedVariant.id}
                  available={selectedVariant.available}
                />
              </>
                }
            </>
            )
            }
        <div dangerouslySetInnerHTML={{__html: productDescription}} />
          

        </div>

        <div style={{
          marginTop: '1rem'
        }}>
          {/* <Img fixed={props.data.shopifyProduct.variants[0].image.localFile.childImageSharp.fixed} alt={props.title}/> */}
          <ProductImageGallery 
            images={props.data.shopifyProduct?.images}
            selectedVariantImageId={selectedVariant?.image?.id}
          />
        </div>
        </Grid>
      
        {/* </StickyContainer> */}
      </ContentBlock>

    </Layout>
  )
}
